<template>
  <DataTable
    :value="parcelItems"
    :rows="100"
    :pt="{
      column: {
        headerCell: {
          class: { 'bg-white border-b border-zinc-200': thWhite }
        }
      }
    }"
  >
    <Column field="DisplayName" :header="t('parcel.articles')" class="w-2/6">
      <template
        #body="{
          data: {
            BundleId,
            DisplayName,
            CancelledBy,
            CancelledReason,
            OriginalArticleNumber
          }
        }"
      >
        <template v-if="CancelledReason">
          <span class="block line-through">
            {{ DisplayName }}
          </span>
          <span class="text-sm text-signal-orange-500">
            {{ $t('parcel.cancelledBy') }} {{ CancelledBy }}:
            {{ cancelledReasonLabel(CancelledReason) }}
          </span>
        </template>
        <template v-else>
          <ParcelItemPopover
            :display-name="DisplayName"
            :bundle-id="BundleId"
            :original-article-number="`${OriginalArticleNumber}`"
          />
        </template>
      </template>
    </Column>
    <Column field="Created" :header="t('parcel.registered')">
      <template #body="{ data: { Created, CancelledReason } }">
        <span :class="{ 'line-through': CancelledReason }">
          {{ formatTimestamp(Created) }}
        </span>
      </template>
    </Column>
    <Column field="ExpectedAmount" :header="t('parcel.expectedAmount')">
      <template
        #body="{ data: { CancelledReason, ExpectedAmount, MedicationUnit } }"
      >
        <span :class="{ 'line-through': CancelledReason }">
          {{ ExpectedAmount }} {{ MedicationUnit }}
        </span>
      </template>
    </Column>
    <Column field="PickedAmount" :header="t('parcel.filledAmount')">
      <template
        #body="{ data: { CancelledReason, MedicationUnit, PickedAmount } }"
      >
        <span :class="{ 'line-through': CancelledReason }">
          {{ PickedAmount }}
          <template v-if="PickedAmount">
            {{ MedicationUnit }}
          </template>
        </span>
      </template>
    </Column>
    <Column field="BrandChange" :header="t('parcel.brandChangeShort')">
      <template #body="{ data: { BrandChange, CancelledReason } }">
        <span :class="{ 'line-through': CancelledReason }">
          {{ BrandChange ? $t('general.yes') : $t('general.no') }}
        </span>
      </template>
    </Column>
  </DataTable>
</template>

<script lang="ts" setup>
import type { ParcelItem } from '~/models'
const { cancelledReasonLabel } = useParcelsData()
const { formatTimestamp } = useHelpers()
const { t } = useI18n()

const { parcelItems } = defineProps<{
  parcelItems: ParcelItem[]
  thWhite?: boolean
}>()
</script>
